import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import { DeviceUUID } from 'device-uuid';
import { getUserAgent } from 'universal-user-agent';
import _ from 'lodash';
import ProgressBar from '../sales-order/components/ProgressBar.jsx';
import { receiveApi } from 'Helpers/helpers';
import { setDefaultServiceMemoData } from 'Redux/actions_ui';
import {
	getCurrentWarehouse,
	updateServiceMemo,
	postApproval,
	confirmServiceMemo,
	holdServiceMemo,
	previewOrder,
	viewOrder,
	printOrder,
} from 'Redux/actions';
import * as cons from 'Redux/constants';
import loc from 'Components/languages';
import PageServiceMemoSalesman from '../sales-order/salesman';
import PageServiceMemoCart from '../sales-order/cart';
import PageServiceMemoPrint from '../sales-order/print';
import ModalPromotions from '../sales-order/components/ModalPromotions.jsx';
import ModalCartException from '../sales-order/components/ModalCartException.jsx';
import ModalApproval from '../order-search/components/ModalApproval.jsx';
import ModalSpecialOptions from '../sales-order/components/ModalSpecialOptions.jsx';

class PageServiceMemo extends PureComponent {
	constructor() {
		super();
		this.defaultServiceMemo = {
			salesman_code1: null,
			salesman_name1: null,
			salesman_code2: null,
			salesman_name2: null,
			optometrist_code: null,
			optometrist_name: null,
			member_code: null,
			member: {
				member_code: null,
				member_name: null,
				member_group: null,
				phone: null,
			},
			doc_type: 'DP1',
			transaction_items: new List(),
			transaction_payments: new List(),
			remark: '',
			coupon_codes: new List(),
			promotion_ids_selected: new List(),
			special_options: new Map(),
			deposit_amount: 0,
			retail_amount: 0,
			total_discount: 0,
			total_quantity: 0,
			net_amount: 0,
			remaining_amount: 0,
			change_amount: 0,
			payment_amount: 0,
			salesman_exceptions: [],
			cart_exceptions: [],
			payment_exceptions: [],
			q_transaction_number: null,
			return_transaction_number: null,
			hold_transaction_number: null,
			is_service_memo: true,
			is_shop_order: false,
			is_approval_needed: false,
			is_approval_verified: false,
			approval_user_code: null,
			allowed_return_payment_methods: null,
		};
		let serviceMemo = new Map(this.defaultServiceMemo);
		if (process.env.DEFAULT_SALESMAN_CODE1) {
			serviceMemo = serviceMemo.set('salesman_code1', process.env.DEFAULT_SALESMAN_CODE1);
		}
		if (process.env.DEFAULT_SALESMAN_NAME1) {
			serviceMemo = serviceMemo.set('salesman_name1', process.env.DEFAULT_SALESMAN_NAME1);
		}
		if (process.env.DEFAULT_SALESMAN_CODE2) {
			serviceMemo = serviceMemo.set('salesman_code2', process.env.DEFAULT_SALESMAN_CODE2);
		}
		if (process.env.DEFAULT_SALESMAN_NAME2) {
			serviceMemo = serviceMemo.set('salesman_name2', process.env.DEFAULT_SALESMAN_NAME2);
		}
		if (process.env.DEFAULT_OPTOMETRIST_CODE) {
			serviceMemo = serviceMemo.set('optometrist_code', process.env.DEFAULT_OPTOMETRIST_CODE);
		}
		if (process.env.DEFAULT_OPTOMETRIST_NAME) {
			serviceMemo = serviceMemo.set('optometrist_name', process.env.DEFAULT_OPTOMETRIST_NAME);
		}
		this.defaultState = {
			serviceMemo,
			signature: null,
			next: null,
			currentDocType: null,
			showModalPromotions: null,
			showModalCartException: false,
			showModalApproval: false,
			showModalSpecialOptions: false,
			approvalUserId: null,
		};
		this.state = {
			...this.defaultState,
		};
		this.progressBar = [
			'salesman',
			'cart',
			'preview',
			'print',
		];
		this.isLoading = this.isLoading.bind(this);
		this.isDisabled = this.isDisabled.bind(this);
		this.setIsVerified = this.setIsVerified.bind(this);
		this.allowHoldOrder = this.allowHoldOrder.bind(this);
		this.getServiceMemo = this.getServiceMemo.bind(this);
		this.getPage = this.getPage.bind(this);
		this.getServiceMemoApiData = this.getServiceMemoApiData.bind(this);
		this.saveServiceMemo = this.saveServiceMemo.bind(this);
		this.resetServiceMemo = this.resetServiceMemo.bind(this);
		this.holdServiceMemo = this.holdServiceMemo.bind(this);
		this.confirmServiceMemo = _.throttle(this.confirmServiceMemo.bind(this), 5000);
		this.viewOrder = this.viewOrder.bind(this);
		this.onStepClickHandler = this.onStepClickHandler.bind(this);
		this.onSelectSalesmanHandler = this.onSelectSalesmanHandler.bind(this);
		this.onSelectMemberHandler = this.onSelectMemberHandler.bind(this);
		this.onChangeQROrderHandler = this.onChangeQROrderHandler.bind(this);
		this.onChangeRemarkHandler = this.onChangeRemarkHandler.bind(this);
		this.onSelectLotNoHandler = this.onSelectLotNoHandler.bind(this);
		this.onAddTransactionItemHandler = this.onAddTransactionItemHandler.bind(this);
		this.onAddTransactionItemsHandler = this.onAddTransactionItemsHandler.bind(this);
		this.onChangeTransactionItemHandler = this.onChangeTransactionItemHandler.bind(this);
		this.onRemoveTransactionItemHandler = this.onRemoveTransactionItemHandler.bind(this);
		this.onRemoveTransactionItemsHandler = this.onRemoveTransactionItemsHandler.bind(this);
		this.onAddCouponCodeHandler = this.onAddCouponCodeHandler.bind(this);
		this.onChangeCouponCodeHandler = this.onChangeCouponCodeHandler.bind(this);
		this.onRemoveCouponCodeHandler = this.onRemoveCouponCodeHandler.bind(this);
		this.onChangeSignatureHandler = this.onChangeSignatureHandler.bind(this);
		this.onPrintHandler = this.onPrintHandler.bind(this);
		this.onToggleModalPromotionsHandler = this.onToggleModalPromotionsHandler.bind(this);
		this.onToggleModalCartExceptionHandler = this.onToggleModalCartExceptionHandler.bind(this);
		this.onToggleModalApprovalHandler = this.onToggleModalApprovalHandler.bind(this);
		this.onRequestApprovalHandler = this.onRequestApprovalHandler.bind(this);
		this.onToggleModalSpecialOptionsHandler = this.onToggleModalSpecialOptionsHandler.bind(this);
		this.onChangeSpecialOptionsHandler = this.onChangeSpecialOptionsHandler.bind(this);
	}

	componentDidMount() {
		const param = queryString.parse(this.props.location.search);
		const defaultServiceMemoData = this.props.defaultServiceMemoData;
		const serviceMemoInfo = this.props.serviceMemoInfo;
		const retrieveHoldServiceMemoInfo = this.props.retrieveHoldServiceMemoInfo;
		let currentDocType = null;

		if (defaultServiceMemoData.data && Object.keys(defaultServiceMemoData.data).length > 0) {
			let { serviceMemo } = this.state;
			[
				'salesman_code1',
				'salesman_name1',
				'salesman_code2',
				'salesman_name2',
				'optometrist_code',
				'optometrist_name',
				'member_code',
				'member',
				'return_transaction_number',
			].forEach((field) => {
				serviceMemo = serviceMemo.set(field, defaultServiceMemoData.data[field]);
			});
			currentDocType = 'SA3';
			this.setState({
				currentDocType,
				serviceMemo: serviceMemo
					.set(
						'doc_type',
						'SA3'
					)
					.set(
						'remark',
						defaultServiceMemoData.data.return_transaction_number
					)
					.set(
						'transaction_items',
						new List(
							defaultServiceMemoData.data.return_items && defaultServiceMemoData.data.return_items.map((transactionItem) => new Map(transactionItem))
						)
					)
				,
			});

			this.props.setDefaultServiceMemoData({ data: {} });
		}

		if (param) {
			switch (param.action) {
			case 'q_to_r':
			case 'deposit_refund':
				switch (serviceMemoInfo.type) {
				case cons.CONVERT_Q_To_R_SERVICEMEMO.SUCCESS:
				case cons.DEPOSIT_REFUND_SERVICEMEMO.SUCCESS: {
					let newState = {
						serviceMemo: this.getServiceMemo(serviceMemoInfo.data.apiData),
						salesmanDisabled: false,
					};
					currentDocType = newState.serviceMemo.get('doc_type');
					if (newState.serviceMemo.get('q_transaction_number')) {
						newState.salesmanDisabled = true;
						newState.serviceMemo = newState.serviceMemo.set('doc_type', 'SA3');
						currentDocType = 'SA3';
					}
					newState.currentDocType = currentDocType;
					this.setState(newState);
					break;
				}
				}
				break;
			case 'hold':
				if (retrieveHoldServiceMemoInfo.type === cons.RETRIEVE_HOLD_SERVICEMEMO.SUCCESS) {
					let newState = {
						serviceMemo: this.getServiceMemo(retrieveHoldServiceMemoInfo.data),
						salesmanDisabled: false,
					};
					currentDocType = newState.serviceMemo.get('doc_type');
					if (newState.serviceMemo.get('q_transaction_number')) {
						newState.salesmanDisabled = true;
						newState.serviceMemo = newState.serviceMemo.set('doc_type', 'SA3');
						currentDocType = 'SA3';
					}
					newState.currentDocType = currentDocType;
					this.setState(newState);
				}
				break;
			}
		}

		if (!currentDocType) {
			const { currentInfo } = this.props;
			if (currentInfo && currentInfo.data && currentInfo.data.default_order_type) {
				this.setState({
					serviceMemo: this.state.serviceMemo.set('doc_type', currentInfo.data.default_order_type === 'r' ? 'SA3' : 'DP1'),
				});
			}
		}

		this.props.getCurrentWarehouse();
	}

	componentDidUpdate(prevProps) {
		const { next } = this.state;
		let { currentDocType } = this.state;
		let newState = {};
		const serviceMemoInfo = this.props.serviceMemoInfo;
		const prevServiceMemoInfo = prevProps.serviceMemoInfo;
		const approvalInfo = this.props.approvalInfo;
		const prevApprovalInfo = prevProps.approvalInfo;
		const serviceMemoConfirmInfo = this.props.serviceMemoConfirmInfo;
		const prevServiceMemoConfirmInfo = prevProps.serviceMemoConfirmInfo;
		const serviceMemoHoldInfo = this.props.serviceMemoHoldInfo;
		const prevServiceMemoHoldInfo = prevProps.serviceMemoHoldInfo;
		const currentInfo = this.props.currentInfo;
		const prevCurrentInfo = prevProps.currentInfo;
		if (
			receiveApi(serviceMemoInfo, prevServiceMemoInfo, cons.UPDATE_SERVICEMEMO) ||
			receiveApi(serviceMemoInfo, prevServiceMemoInfo, cons.CONVERT_Q_To_R_SERVICEMEMO) ||
			receiveApi(serviceMemoInfo, prevServiceMemoInfo, cons.DEPOSIT_REFUND_SERVICEMEMO)
		) {
			newState.serviceMemo = this.getServiceMemo(serviceMemoInfo.data.apiData);
			newState.salesmanDisabled = false;
			if (newState.serviceMemo.get('q_transaction_number') || newState.serviceMemo.get('return_transaction_number')) {
				newState.salesmanDisabled = true;
				newState.serviceMemo = newState.serviceMemo.set('doc_type', 'SA3');
				currentDocType = 'SA3';
			}
		}

		if (serviceMemoInfo && serviceMemoInfo !== prevServiceMemoInfo) {
			if (serviceMemoInfo.type === cons.UPDATE_SERVICEMEMO.SUCCESS) {
				if (
					serviceMemoInfo.data &&
					serviceMemoInfo.data.apiData &&
					serviceMemoInfo.data.apiData.cart_exceptions &&
					serviceMemoInfo.data.apiData.cart_exceptions.length > 0
				) {
					if (next === 'preview' || next === 'print') {
						if (
							serviceMemoInfo.data.apiData.is_approval_needed &&
							!serviceMemoInfo.data.apiData.is_approval_verified
						) {
							newState.showModalApproval = true;
						} else {
							newState.next = null;
							newState.showModalCartException = true;
						}
					} else if (next) {
						newState.next = null;
						this.props.history.push(next);
					}
				} else {
					if (next === 'print') {
						this.confirmServiceMemo();
					} else if (next) {
						if (next === 'preview') {
							this.previewServiceMemo();
						}
						this.props.history.push(next);
						newState.next = null;
					}
				}
			}
		}

		if (approvalInfo && approvalInfo !== prevApprovalInfo) {
			switch (approvalInfo.type) {
			case cons.POST_APPROVAL.SUCCESS:
				this.setIsVerified(approvalInfo.data.is_verfied);
				break;
			case cons.POST_APPROVAL.FAILURE:
				this.setIsVerified(false);
				break;
			}
		}

		if (serviceMemoConfirmInfo && serviceMemoConfirmInfo !== prevServiceMemoConfirmInfo) {
			if (serviceMemoConfirmInfo.type === cons.CONFIRM_SERVICEMEMO.SUCCESS) {
				this.viewOrder();
				if (next) {
					newState.next = null;
					this.props.history.push(next);
				}
			} else if (serviceMemoConfirmInfo.type === cons.CONFIRM_SERVICEMEMO.FAILURE) {
				newState.next = null;
			}
		}

		if (serviceMemoHoldInfo && serviceMemoHoldInfo !== prevServiceMemoHoldInfo) {
			switch (serviceMemoHoldInfo.type) {
			case cons.HOLD_SERVICEMEMO.SUCCESS:
				alert(`${serviceMemoHoldInfo.data.transaction_number} is saved`);
				break;
			case cons.HOLD_SERVICEMEMO.FAILURE:
				if (serviceMemoHoldInfo.error.code) {
					alert(loc[serviceMemoHoldInfo.error.code]);
				}
				break;
			}
		}

		if (currentInfo && currentInfo !== prevCurrentInfo) {
			if (!currentDocType && currentInfo && currentInfo.data && currentInfo.data.default_order_type) {
				newState.currentDocType = currentDocType;
				newState.serviceMemo = newState.serviceMemo || this.state.serviceMemo;
				newState.serviceMemo = newState.serviceMemo.set('doc_type', currentInfo.data.default_order_type === 'r' ? 'SA3' : 'DP1');
			}
		}

		this.setState(newState);
	}

	isLoading() {
		const { currentWarehouseInfo, serviceMemoInfo, previewOrderInfo, serviceMemoConfirmInfo } = this.props;
		return currentWarehouseInfo.isFetching || serviceMemoInfo.isFetching || previewOrderInfo.isFetching || serviceMemoConfirmInfo.isFetching;
	}

	isDisabled() {
		let ret = this.isLoading();
		if (ret) {
			return ret;
		}
		const { serviceMemo } = this.state;
		return !serviceMemo.get('salesman_code1') || !serviceMemo.get('salesman_code2') || !serviceMemo.get('optometrist_code');
	}

	setIsVerified(isVerified) {
		const { approvalUserId, serviceMemo } = this.state;
		this.setState({
			serviceMemo: serviceMemo
				.set('is_approval_verified', isVerified)
				.set('approval_user_code', approvalUserId)
			,
			showModalApproval: !isVerified,
		}, isVerified ? this.saveServiceMemo : undefined);
	}

	allowHoldOrder() {
		const { serviceMemoInfo } = this.props;
		const apiData = serviceMemoInfo && serviceMemoInfo.data && serviceMemoInfo.data.apiData;
		return (
			apiData &&
			apiData.transaction_items.length > 0 &&
			apiData.salesman_exceptions.length === 0 &&
			!apiData.q_transaction_number &&
			!apiData.return_transaction_number &&
			this.getPage() !== 'print'
		);
	}

	getServiceMemo(serviceMemoApiData) {
		let { serviceMemo } = this.state;
		serviceMemo = serviceMemo.merge(serviceMemoApiData);
		serviceMemo = serviceMemo
			.set('doc_type', serviceMemo.get('doc_type').trim())
			.set(
				'transaction_items',
				new List(
					serviceMemo.get('transaction_items').map((transactionItem) => new Map(transactionItem))
				)
			)
			.set(
				'transaction_payments',
				new List(
					serviceMemo.get('transaction_payments').map((transactionPayment) => new Map(transactionPayment))
				)
			)
			.set(
				'coupon_codes',
				new List(
					serviceMemo.get('coupon_codes')
				)
			)
			.set(
				'promotion_ids_selected',
				new List(
					serviceMemo.get('promotion_ids_selected')
				)
			)
		;
		if (serviceMemo.get('special_options')) {
			let specialOptions = {};
			serviceMemo.get('special_options').forEach((specialOption) => {
				specialOptions[specialOption.name] = specialOption.value;
			});
			serviceMemo = serviceMemo.set('special_options', new Map(specialOptions));
		}
		return serviceMemo;
	}

	getPage(props) {
		if (!props) {
			props = this.props;
		}
		return props.match.params.page;
	}

	getServiceMemoApiData() {
		const { serviceMemo } = this.state;
		let ret = serviceMemo.toJS();

		// Handle payment
		ret.transaction_payments = ret.transaction_payments.map((transactionPayment) => ({
			...transactionPayment,
			amount: parseFloat(transactionPayment.amount) || 0,
		}));

		// Handle special options
		let special_options = [];
		for (let i in ret.special_options) {
			special_options.push({
				name: i,
				value: ret.special_options[i],
			});
		}
		ret.special_options = special_options;

		ret.tracking = {
			type: 'web',
			uuid: uuidv4(),
			deviceUuid: new DeviceUUID().get(),
			userAgent: getUserAgent(),
		};

		return ret;
	}

	saveServiceMemo() {
		let param = {
			payload: {
				apiData: this.getServiceMemoApiData(),
			},
		};
		param.payload.apiData.step = this.getPage();
		this.props.updateServiceMemo(param);
	}

	resetServiceMemo() {
		this.setState({
			...this.defaultState,
		}, () => {
			let param = {
				payload: {
					apiData: this.getServiceMemoApiData(),
				},
				reset: true,
			};
			param.payload.apiData.step = 'salesman';
			this.props.updateServiceMemo(param);
			this.props.history.push('salesman');
		});
	}

	holdServiceMemo() {
		const param = {
			payload: {
				apiData: this.getServiceMemoApiData(),
			},
		};
		this.props.holdServiceMemo(param);
	}

	previewServiceMemo() {
		const { signature } = this.state;
		const param = {
			payload: {
				apiData: {
					...this.getServiceMemoApiData(),
					signature,
					step: this.getPage(),
				}
			},
		};
		this.props.previewOrder(param);
	}

	confirmServiceMemo() {
		const param = {
			payload: {
				apiData: this.getServiceMemoApiData(),
			},
		};
		this.props.confirmServiceMemo(param);
	}

	viewOrder() {
		const { serviceMemoConfirmInfo } = this.props;
		const { signature } = this.state;
		if (
			serviceMemoConfirmInfo &&
			serviceMemoConfirmInfo.data &&
			serviceMemoConfirmInfo.data.apiData &&
			serviceMemoConfirmInfo.data.apiData.transaction_number
		) {
			const param = {
				payload: {
					trx_no: serviceMemoConfirmInfo.data.apiData.transaction_number,
					signature,
				},
			};
			this.props.viewOrder(param);
		}
	}

	onStepClickHandler(step) {
		let handler;
		switch (step) {
		case 'salesman':
		case 'cart':
		case 'preview':
			handler = this.saveServiceMemo;
			break;
		case 'print':
			handler = this.confirmServiceMemo;
			break;
		}
		this.setState({
			next: step,
		}, handler);
	}

	onSelectSalesmanHandler(salesmanType, salesman) {
		const { serviceMemo } = this.state;
		switch (salesmanType) {
		case 'salesman1':
			this.setState({
				serviceMemo: serviceMemo
					.set('salesman_code1', salesman ? salesman.user_id : null)
					.set('salesman_name1', salesman ? salesman.user_name : null)
				,
			});
			break;
		case 'salesman2':
			this.setState({
				serviceMemo: serviceMemo
					.set('salesman_code2', salesman ? salesman.user_id : null)
					.set('salesman_name2', salesman ? salesman.user_name : null)
				,
			});
			break;
		case 'optometrist':
			this.setState({
				serviceMemo: serviceMemo
					.set('optometrist_code', salesman ? salesman.user_id : null)
					.set('optometrist_name', salesman ? salesman.user_name : null)
				,
			});
			break;
		}
	}

	onSelectMemberHandler(member) {
		const { serviceMemo } = this.state;
		this.setState({
			serviceMemo: serviceMemo
				.set('member_code', member.member_code)
				.set('member', member)
			,
		});
	}

	onChangeQROrderHandler(type) {
		let { serviceMemo } = this.state;
		if (serviceMemo.get('q_transaction_number') || serviceMemo.get('return_transaction_number')) {
			type = 'r';
		}
		switch (type) {
		case 'q':
			serviceMemo = serviceMemo.set('doc_type', 'DP1');
			break;
		case 'r':
			serviceMemo = serviceMemo.set('doc_type', 'SA3');
			break;
		default:
			serviceMemo = serviceMemo.remove('doc_type');
			break;
		}
		this.setState({
			serviceMemo,
		}, this.saveServiceMemo);
	}

	onChangeRemarkHandler(remark) {
		const { serviceMemo } = this.state;
		this.setState({
			serviceMemo: serviceMemo.set('remark', remark),
		});
	}

	onSelectLotNoHandler(index, lotNo) {
		const { serviceMemo } = this.state;
		const transactionItem = serviceMemo.get('transaction_items').get(index);
		this.setState({
			serviceMemo: serviceMemo
				.set(
					'transaction_items',
					serviceMemo.get('transaction_items').set(
						index,
						transactionItem.set('lot_no', lotNo.lot_no)
					)
				)
			,
		}, this.saveServiceMemo);
	}

	onAddTransactionItemHandler(transactionItem) {
		const { serviceMemo } = this.state;
		this.setState({
			serviceMemo: serviceMemo
				.set(
					'transaction_items',
					serviceMemo.get('transaction_items').push(transactionItem)
				)
			,
		}, this.saveServiceMemo);
	}

	onAddTransactionItemsHandler(transactionItems) {
		const { serviceMemo } = this.state;
		this.setState({
			serviceMemo: serviceMemo
				.set(
					'transaction_items',
					serviceMemo.get('transaction_items').concat(transactionItems)
				)
			,
		}, this.saveServiceMemo);
	}

	onChangeTransactionItemHandler(index, data) {
		const { serviceMemo } = this.state;
		const transactionItem = serviceMemo.get('transaction_items').get(index);
		this.setState({
			serviceMemo: serviceMemo
				.set(
					'transaction_items',
					serviceMemo.get('transaction_items').set(
						index,
						transactionItem
							.set('item_quantity', data.item_quantity)
							.set('manual_price_amount', data.manual_price_amount)
							.set('discount_percentage', data.discount_percentage)
							.set('discount_reduction', data.discount_reduction)
							.set('discount_amount', data.discount_amount)
					)
				)
			,
		}, this.saveServiceMemo);
	}

	onRemoveTransactionItemHandler(index) {
		const { serviceMemo } = this.state;
		this.setState({
			serviceMemo: serviceMemo
				.set(
					'transaction_items',
					serviceMemo.get('transaction_items').delete(index)
				)
			,
		}, this.saveServiceMemo);
	}

	onRemoveTransactionItemsHandler(indices) {
		const { serviceMemo } = this.state;
		this.setState({
			serviceMemo: serviceMemo
				.set(
					'transaction_items',
					serviceMemo.get('transaction_items').filter((transactionItem, index) => (
						indices.indexOf(index) === -1
					))
				)
			,
		}, this.saveServiceMemo);
	}

	onAddCouponCodeHandler(couponCode) {
		const { serviceMemo } = this.state;
		this.setState({
			serviceMemo: serviceMemo
				.set(
					'coupon_codes',
					serviceMemo.get('coupon_codes').push(couponCode),
				)
			,
		}, this.saveServiceMemo);
	}

	onChangeCouponCodeHandler(couponCode, data) {
		if (data.quantity <= 0) {
			return this.onRemoveCouponCodeHandler(couponCode);
		}

		const { serviceMemo } = this.state;
		let couponCodeMap = {};
		serviceMemo.get('coupon_codes').forEach((couponCode) => {
			if (!couponCodeMap[couponCode]) {
				couponCodeMap[couponCode] = {
					couponCode,
					quantity: 0,
				};
			}
			couponCodeMap[couponCode].quantity++;
		});
		couponCodeMap[couponCode].quantity = data.quantity;

		let tmp = Object.values(couponCodeMap).sort((a, b) => a.couponCode.localeCompare(b.couponCode));
		let couponCodes = [];
		for (let i = 0; i < tmp.length; i++) {
			for (let j = 0; j < tmp[i].quantity; j++) {
				couponCodes.push(tmp[i].couponCode);
			}
		}

		this.setState({
			serviceMemo: serviceMemo
				.set(
					'coupon_codes',
					new List(couponCodes)
				)
			,
		}, this.saveServiceMemo);
	}

	onRemoveCouponCodeHandler(couponCode) {
		const { serviceMemo } = this.state;
		this.setState({
			serviceMemo: serviceMemo
				.set(
					'coupon_codes',
					serviceMemo.get('coupon_codes').filterNot((c) => c === couponCode)
				)
			,
		}, this.saveServiceMemo);
	}

	onChangeSignatureHandler(signature) {
		const page = this.getPage();
		this.setState({
			signature,
		}, page === 'preview' ? this.previewServiceMemo : this.viewOrder);
	}

	onPrintHandler() {
		const { serviceMemoConfirmInfo } = this.props;
		const { signature } = this.state;
		if (
			serviceMemoConfirmInfo &&
			serviceMemoConfirmInfo.data &&
			serviceMemoConfirmInfo.data.apiData &&
			serviceMemoConfirmInfo.data.apiData.transaction_number
		) {
			const param = {
				payload: {
					trx_no: serviceMemoConfirmInfo.data.apiData.transaction_number,
					signature,
				},
			};
			this.props.printOrder(param);
		}
	}

	onToggleModalPromotionsHandler(tab) {
		const { showModalPromotions } = this.state;
		this.setState({
			showModalPromotions: showModalPromotions ? null : tab,
		});
	}

	onToggleModalCartExceptionHandler() {
		const { showModalCartException } = this.state;
		this.setState({
			showModalCartException: !showModalCartException,
		});
	}

	onToggleModalApprovalHandler() {
		this.setState({
			showModalApproval: false,
		});
	}

	onRequestApprovalHandler({ user_id, code }) {
		const param = {
			payload: {
				user_id,
				code,
			},
		};
		this.setState({
			approvalUserId: user_id,
		});
		this.props.postApproval(param);
	}

	onToggleModalSpecialOptionsHandler() {
		const { showModalSpecialOptions } = this.state;
		this.setState({
			showModalSpecialOptions: !showModalSpecialOptions,
		});
	}

	onChangeSpecialOptionsHandler(name, value) {
		const { serviceMemo } = this.state;
		const specialOptions = serviceMemo.get('special_options');
		this.setState({
			serviceMemo: serviceMemo.set('special_options', specialOptions.set(name, value)),
		});
	}

	render() {
		const { serviceMemo, signature, showModalPromotions, showModalCartException, showModalApproval, showModalSpecialOptions } = this.state;
		const { serviceMemoInfo, history } = this.props;
		const isDisabled = this.isDisabled();
		const isLoading = this.isLoading();
		const commonProps = {
			salesOrder: serviceMemo,
			type: 'serviceMemo',
			isDisabled,
			isLoading,
			onStepClick: this.onStepClickHandler,
			onToggleModalSpecialOptions: this.onToggleModalSpecialOptionsHandler,
		};
		const page = this.getPage();
		let body = null;
		switch (page) {
		case 'salesman':
			body = (
				<PageServiceMemoSalesman
					next={ this.progressBar[1] }
					onSelectSalesman={ this.onSelectSalesmanHandler }
					onSelectMember={ this.onSelectMemberHandler }
					{ ...commonProps }
				/>
			);
			break;
		case 'cart':
			body = (
				<PageServiceMemoCart
					next={ this.progressBar[2] }
					onChangeQROrder={ this.onChangeQROrderHandler }
					onChangeRemark={ this.onChangeRemarkHandler }
					onSelectLotNo={ this.onSelectLotNoHandler }
					onToggleModalPromotions={ this.onToggleModalPromotionsHandler }
					onAddTransactionItem={ this.onAddTransactionItemHandler }
					onAddTransactionItems={ this.onAddTransactionItemsHandler }
					onChangeTransactionItem={ this.onChangeTransactionItemHandler }
					onRemoveTransactionItem={ this.onRemoveTransactionItemHandler }
					onRemoveTransactionItems={ this.onRemoveTransactionItemsHandler }
					onAddCouponCode={ this.onAddCouponCodeHandler }
					onChangeCouponCode={ this.onChangeCouponCodeHandler }
					onRemoveCouponCode={ this.onRemoveCouponCodeHandler }
					{ ...commonProps }
				/>
			);
			break;
		case 'preview':
		case 'print':
			body = (
				<PageServiceMemoPrint
					preview={ page === 'preview' }
					next={ page === 'preview' ? this.progressBar[3] : null }
					signature={ signature }
					onChangeSignature={ this.onChangeSignatureHandler }
					onPrint={ this.onPrintHandler }
					onReset={ this.resetServiceMemo }
					{ ...commonProps }
				/>
			);
			break;
		}

		return (
			<Fragment>
				<div className="uk-flex uk-flex-column">
					<ProgressBar
						name="serviceMemo"
						page={ page }
						items={ this.progressBar }
						salesOrder={ serviceMemo }
						history={ history }
						onStepClick={ this.onStepClickHandler }
						onReset={ this.resetServiceMemo }
						onHold={ this.allowHoldOrder() ? this.holdServiceMemo : undefined }
						onToggleModalPromotions={ this.onToggleModalPromotionsHandler }
					/>
					<div>
						{ body }
					</div>
				</div>

				<ModalPromotions
					isOpen={ showModalPromotions }
					isLoading={ isLoading }
					type="serviceMemo"
					current={ page }
					onToggle={ this.onToggleModalPromotionsHandler }
					salesOrder={ serviceMemo }
				/>

				<ModalCartException
					isOpen={ showModalCartException }
					onToggle={ this.onToggleModalCartExceptionHandler }
					data={ serviceMemoInfo }
				/>

				<ModalApproval
					isOpen={ showModalApproval }
					onToggle={ this.onToggleModalApprovalHandler }
					onSubmit={ this.onRequestApprovalHandler }
				/>

				<ModalSpecialOptions
					isOpen={ showModalSpecialOptions }
					onToggle={ this.onToggleModalSpecialOptionsHandler }
					onChange={ this.onChangeSpecialOptionsHandler }
					salesOrder={ serviceMemo }
				/>
			</Fragment>
		);
	}
}

export default connect(
	(state) => ({
		defaultServiceMemoData: state.defaultServiceMemoData,
		currentWarehouseInfo: state.currentWarehouseInfo,
		serviceMemoInfo: state.serviceMemoInfo,
		serviceMemoConfirmInfo: state.serviceMemoConfirmInfo,
		serviceMemoHoldInfo: state.serviceMemoHoldInfo,
		retrieveHoldServiceMemoInfo: state.retrieveHoldServiceMemoInfo,
		currentInfo: state.currentInfo,
		previewOrderInfo: state.previewOrderInfo,
		viewOrderInfo: state.viewOrderInfo,
		approvalInfo: state.approvalInfo,
	}),
	(dispatch) => ({
		setDefaultServiceMemoData: para => dispatch(setDefaultServiceMemoData(para)),
		getCurrentWarehouse: para => dispatch(getCurrentWarehouse(para)),
		updateServiceMemo: para => dispatch(updateServiceMemo(para)),
		postApproval: (para) => dispatch(postApproval(para)),
		confirmServiceMemo: para => dispatch(confirmServiceMemo(para)),
		holdServiceMemo: para => dispatch(holdServiceMemo(para)),
		previewOrder: para => dispatch(previewOrder(para)),
		viewOrder: para => dispatch(viewOrder(para)),
		printOrder: para => dispatch(printOrder(para)),
	})
)(PageServiceMemo);